import { Box, Button, Form, FormField, TextInput, TextArea, Select, MaskedInput } from 'grommet'
import { useEffect, useState } from 'react';
import ImageUploading from "react-images-uploading";
import Resizer from "react-image-file-resizer";

import "./EditableSale.css"


const EditableSaleItem = ({ item, adminConfigs, editSale, deleteSale }) => {
  const [images, setImages] = useState(item.images);
  const [value, setValue] = useState(item);
  const [saveDisabled, setSaveDisabled] = useState(true);

  const resizeImage = (file) => {
    const quality = 100;
    return new Promise((resolve) => {
      Resizer.imageFileResizer(file, 420, 350, "JPEG", quality, 0, (uri) => { resolve(uri); }, "blob", 420, 350);
    });
  };

  const fileUpload = async (files) => {
    const newList = []
    let index = 0;

    while (files[index]) {
      let currentFile = files[index]
      if (typeof currentFile !== "string") {
        currentFile = { file: await resizeImage(currentFile.file), data_url: currentFile.data_url };
      }
      newList.push(currentFile)
      index++;
    }

    setImages(newList)
    setSaveDisabled(false);
  }


  const saveChanges = () => {
    editSale(item.id, { ...value, images });
    setSaveDisabled(true);
  }


  return (
    <div className="sale-modal">
      <div style={{ marginBottom: 12 }}>
        <ImageUploading
          multiple
          value={images}
          onChange={fileUpload}
          maxNumber={10}
          dataURLKey="data_url"
        >
          {({
            imageList,
            onImageUpload,
            onImageUpdate,
            onImageRemove,
            isDragging,
            dragProps
          }) => (
              // write your building UI
              <div className="upload__image-wrapper">
                <Button secondary label="Add images" onClick={onImageUpload} {...dragProps} style={{ margin: 4 }} />

                <div className="upload__image-list">
                  {imageList.map((image, index) => (
                    <div key={index} className="upload__image-item">
                      <img src={image.data_url || image} alt="" width="100" height="100" />
                      <div className="upload__image-item-btns">
                        <button onClick={() => onImageUpdate(index)}>Update</button>
                        <button onClick={() => onImageRemove(index)}>Remove</button>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
        </ImageUploading>
      </div>
      <Form
        className="sale-form"
        value={value}
        onChange={nextValue => {
          setValue({ ...value, ...nextValue });
          setSaveDisabled(false)
        }}
        onReset={() => {
          setValue(item);
          setSaveDisabled(false);
        }}
        onSubmit={saveChanges}
      >
        <div className="fields">
          <div className="fields-column">
            <FormField style={{ minWidth: '50%' }} name="title" label="Title">
              <TextInput name="title" placeholder="type here" />
            </FormField>
          </div>
          <div className="fields-column">
            <FormField name="price" label="Price">
              <TextInput name="price" placeholder="type here $" />
            </FormField>
            <FormField name="kilometers" label="Kilometers">
              <TextInput name="kilometers" placeholder="type here" />
            </FormField>
          </div>
          <div className="fields-column">
            <FormField name="company" label="Make">
              <TextInput name="company" placeholder="type here" />
            </FormField>
            <FormField name="model" label="Model">
              <TextInput name="model" placeholder="type here" />
            </FormField>
            <FormField name="year" label="Year">
              <MaskedInput
                name="year"
                mask={[
                  {
                    length: 1,
                    regexp: /[1-2]{1}$/,
                    options: ['1990', '2000', '2010', '2020'],
                    placeholder: 'y',
                  },
                  {
                    length: 1,
                    regexp: /[0-9]{1}$/,
                    placeholder: 'e',
                  },
                  {
                    length: 1,
                    regexp: /[0-9]{1}$/,
                    placeholder: 'a',
                  },
                  {
                    length: 1,
                    regexp: /[0-9]{1}$/,
                    placeholder: 'r',
                  },
                ]}
              />
            </FormField>
          </div>
          <div className="fields-column">
            <FormField name="engine" label="Engine">
              <TextInput name="engine" placeholder="type here" />
            </FormField>
            <FormField name="transmission" label="Transmission">
              <TextInput name="transmission" placeholder="type here" />
            </FormField>
          </div>
          <div className="fields-column">
            <FormField name="drivespec" label="Drive spec">
              <TextInput name="drivespec" placeholder="type here" />
            </FormField>
            <FormField name="steerspec" label="Steer spec">
              <TextInput name="steerspec" placeholder="type here" />
            </FormField>
            <FormField name="ratio" label="Ratio">
              <TextInput name="ratio" placeholder="type here" />
            </FormField>
          </div>
          <div style={{ width: '90%', marginBottom: 24 }}>
            <FormField name="description" label="Description">
              <TextArea name="description" placeholder="description" style={{ border: 'solid 2px gray' }} rows={10} />
            </FormField>
          </div>
        </div>
        <div className="buttons" style={{ width: '100%' }}>
          <FormField name="state" label="State" style={{ width: 150 }}>
            <Select
              name="state"
              options={['Creating', 'Service', 'Product', 'For sale', 'On hold', 'Sold']}
            />
          </FormField>
          <Box direction="column" gap="medium" className="edit-buttons">
            <Button disabled={saveDisabled} type="submit" primary label="Save" />
            <Button type="reset" label="Reset" />
            {adminConfigs.isSuperAdmin && <Button onClick={() => deleteSale(item.id)} className="delete-button" label="Delete" />}
          </Box>
        </div>
      </Form>
    </div >
  );
}

export default EditableSaleItem