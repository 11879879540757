import { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Redirect, useLocation } from "react-router-dom";
import firebase from 'firebase/app';

import { AppBar, AppFooter } from "./common/components"
import { ServicesContainer } from "./services/components"
import { Home } from "./info/components";

import './App.css';
import SaleList from 'sales/components/SaleList';

require('firebase/auth');
require('firebase/firestore');
require('firebase/storage');

var firebaseConfig = {
  apiKey: "AIzaSyADuNcI0xp4e4I9mtYZ7FM0DJkOLB9qQZQ",
  authDomain: "dbheavymechanical.firebaseapp.com",
  databaseURL: "https://dbheavymechanical.firebaseio.com",
  projectId: "dbheavymechanical",
  storageBucket: "dbheavymechanical.appspot.com",
  messagingSenderId: "977109330951",
  appId: "1:977109330951:web:05e510bb8c993295bf8ea2",
  measurementId: "G-MFETZECZJ6"
};

firebase.initializeApp(firebaseConfig);

const SalesDb = firebase.firestore().collection('/sales');
const SalesStorageRef = firebase.storage().ref("/Sales");

const truckStorageRef = firebase.storage().ref("/Cards/Truck");
const trailerStorageRef = firebase.storage().ref("/Cards/Trailer");
const h24StorageRef = firebase.storage().ref("/Cards/H24Service");
const designStorageRef = firebase.storage().ref("/Cards/Design");
const carouselDesignStorageRef = firebase.storage().ref("/Cards/CarouselDesign");

const carouselStorageRef = firebase.storage().ref("/Carousel");
const sliderStorageRef = firebase.storage().ref("/Slider");

function uuid() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}

const useFetchingImages = () => {
  const { pathname } = useLocation();

  const [truckImage, setTruckImage] = useState(undefined)
  const [trailerImage, setTrailerImage] = useState(undefined)
  const [h24Image, setH24Image] = useState(undefined)
  const [designImage, setDesignImage] = useState(undefined)

  const [carouselImages, setCarouselImages] = useState([])
  const [sliderImages, setSliderImages] = useState([])

  useEffect(() => {
    if (!(truckImage && trailerImage && h24Image)) {
      truckStorageRef.listAll().then(async (result) => {
        const truckImage = await result.items[0]?.getDownloadURL()
        setTruckImage(truckImage)
      })
      trailerStorageRef.listAll().then(async (result) => {
        const trailerImage = await result.items[0]?.getDownloadURL()
        setTrailerImage(trailerImage)
      })
      h24StorageRef.listAll().then(async (result) => {
        const h24Image = await result.items[0]?.getDownloadURL()
        setH24Image(h24Image)
      })
      designStorageRef.listAll().then(async (result) => {
        const designImage = await result.items[0]?.getDownloadURL()
        setDesignImage(designImage)
      })
    }

    if (!(carouselImages.length && sliderImages.length) && pathname.includes('/home')) {
      carouselStorageRef.listAll().then(async (result) => {
        const carouselUrls = await Promise.all(result.items.map(async img => `${await img.getDownloadURL()}`))
        setCarouselImages(carouselUrls)
      })
      sliderStorageRef.listAll().then(async (result) => {
        const sliderUrls = await Promise.all(result.items.map(async img => `${await img.getDownloadURL()}`))
        setSliderImages(sliderUrls)
      })
    }

  }, [pathname])

  return { carouselImages, sliderImages, truckImage, trailerImage, h24Image, designImage }
}

const App = () => {
  const [user, setUser] = useState(undefined)
  const [adminConfigs, setAdminConfigs] = useState({ isAdmin: false, isSuperAdmin: false })
  const [saleList, setSaleList] = useState(undefined)
  const [carouselDesignImages, setCarouselDesignImages] = useState([])

  const imagesObject = useFetchingImages()

  useEffect(() => {
    const currentUser = firebase.auth().currentUser
    currentUser ? currentUser.getIdTokenResult()
      .then((idTokenResult) => {
        setAdminConfigs({
          isAdmin: !!idTokenResult.claims.isAdmin,
          isSuperAdmin: !!idTokenResult.claims.isSuperAdmin,
        })
      })
      .catch((error) => {
        console.log(error);
        setAdminConfigs({
          isAdmin: false,
          isSuperAdmin: false,
        })
      }) : setAdminConfigs({ isAdmin: false, isSuperAdmin: false })
  }, [firebase.auth().currentUser])


  const fetchSales = async (showCreatingSales) => {
    const sales = await SalesDb
      .orderBy('state')
      .where('state', "!=", showCreatingSales ? "" : "Creating")
      .orderBy('date', 'desc')
      .get()

    setSaleList(sales.docs.map(doc => ({ id: doc.id, ...doc.data() })))
  }

  const fetchCarouselDesignImages = async () => {
    carouselDesignStorageRef.listAll().then(async (result) => {
      const carouselUrls = await Promise.all(result.items.map(async img => `${await img.getDownloadURL()}`))
      setCarouselDesignImages(carouselUrls)
    })
  }

  const createSale = async () => {
    const presetValues = {
      company: "",
      description: "",
      kilometers: "0",
      model: "",
      price: "0",
      state: "Creating",
      title: "",
      year: "0000",
      images: [],
      date: firebase.firestore.Timestamp.now(),
    }
    const newSale = await SalesDb.add(presetValues);
    presetValues.id = newSale.id
    setSaleList([presetValues].concat(saleList))
  }

  const editSale = async (uId, data) => {
    if (adminConfigs.isAdmin && uId) {
      const sale = SalesDb.doc(uId);

      const oldSale = saleList.find(({ id }) => id === uId);
      const { id, images, ...rest } = data;
      const newData = { ...rest, images: [] }

      let index = 0;
      while (!!images[index]) {
        const newImage = images[index]

        let imageUrl = ""
        if (typeof newImage === "string") {
          imageUrl = newImage
        } else {
          imageUrl = await (await uploadSaleImage(newImage.file, `${uuid()}`)).getDownloadURL()
        }
        newData.images.push(imageUrl)

        index++;
      }

      oldSale.images.forEach(imageUrl => {
        if (!newData.images.includes(imageUrl)) {
          deleteSaleImage(imageUrl)
        }
      });

      await sale.update(newData);
      fetchSales(adminConfigs.isAdmin || adminConfigs.isSuperAdmin);
    }
  }

  const deleteSale = async (uId) => {
    if (adminConfigs.isSuperAdmin) {
      const sale = SalesDb.doc(uId)
      const localSale = saleList.find(({ id }) => id === uId);

      localSale.images.forEach(imageUrl => {
        deleteSaleImage(imageUrl);
      });

      await sale.delete();
      fetchSales(adminConfigs.isAdmin || adminConfigs.isSuperAdmin);
    }
  }

  const uploadSaleImage = async (imagedata, name) => {
    const metadata = {
      contentType: 'image/jpeg',
    };

    return new Promise((resolve) => {
      const uploadTask = SalesStorageRef.child(`${name}.jpg`).put(imagedata, metadata);
      uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, {
        'complete': function () {
          resolve(SalesStorageRef.child(`${name}.jpg`));
        }
      });
    });

  }

  const deleteSaleImage = async (url) => {
    const uId = url.split('Sales%2F')[1].split('.jpg')[0]

    const file = SalesStorageRef.child(`${uId}.jpg`)
    try {
      return await file.delete();
    } catch (e) {
      return false;
    }
  }

  return (
    <div className="App" style={{}}>
      <AppBar adminConfigs={adminConfigs} />

      <div className="App-content" id="appContent">
        <Route exact path="/">
          <Redirect to="/home" />
        </Route>
        <Route exact path="/home">
          <Home {...imagesObject} />
        </Route>
        <Route path={["/services"]}>
          <ServicesContainer
            {...imagesObject}
            carouselDesignImages={carouselDesignImages}
            fetchCarouselDesignImages={fetchCarouselDesignImages}
          />
        </Route>
        <Route path="/store">
          <SaleList
            saleList={saleList}
            adminConfigs={adminConfigs}
            fetchSales={fetchSales}
            createSale={createSale}
            editSale={editSale}
            deleteSale={deleteSale}
          />
        </Route>
      </div>

      <AppFooter setUser={setUser} />
    </div>
  );
}

const RouterApp = () => {
  return (
    <Router >
      <App />
    </Router>
  )
}

export default RouterApp;
