import { useEffect, useState } from "react"
import { useHistory, useLocation } from "react-router-dom";

import { Modal } from "common/components";
import H24ServicesCard, { H24ServicesCardModal } from "services/components/ServiceCards/H24ServicesCard";
import TrailerCard, { TrailerCardModal } from "services/components/ServiceCards/TrailerCard";
import TruckCard, { TruckCardModal } from "services/components/ServiceCards/TruckCard";
import CustomCard, { CustomCardModal } from "services/components/ServiceCards/CustomCard";

import './ServicesContainer.css';

const sections = {
  'truck': () => TruckCardModal,
  'trailer': () => TrailerCardModal,
  'road': () => H24ServicesCardModal,
  'design': (carouselImages) => CustomCardModal(carouselImages)
}

const ServicesContainer = ({ truckImage, trailerImage, h24Image, designImage, carouselDesignImages, fetchCarouselDesignImages }) => {
  const history = useHistory();
  const { pathname } = useLocation();

  const [modalContent, setModalContent] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const openSection = (section) => {
    history.push(`/services/${section}`);
  }

  const closeSection = () => {
    history.push(`/services`);
  }

  useEffect(() => {
    const section = pathname.split('/').pop()

    const openModal = async () => {
      if (sections[section] && !showModal) {
        await setModalContent(sections[section](carouselDesignImages))
        setShowModal(true)
      } else if (!sections[section] && showModal) {
        setShowModal(false)
      }
    }

    if (carouselDesignImages.length) {
      openModal()
    }
  }, [pathname, carouselDesignImages])

  useEffect(() => document.getElementById("appContent").scrollTo(0, 0), [])

  useEffect(fetchCarouselDesignImages, [])

  return (
    <div className="services noselect fade-in">
      <div className="services-row row1">
        <TruckCard truckImage={truckImage} openSection={() => openSection('truck')} />
        <TrailerCard trailerImage={trailerImage} openSection={() => openSection('trailer')} />
      </div>
      <div className="services-row row2">
        <H24ServicesCard h24Image={h24Image} openSection={() => openSection('road')} />
        <CustomCard designImage={designImage} openSection={() => openSection('design')} />
      </div>
      <Modal showPhone={!(modalContent?.props.className?.includes('hide-phone'))} content={modalContent} visible={showModal} onClose={closeSection} />
    </div>
  );
}

export default ServicesContainer;