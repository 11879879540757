import { Footer } from 'grommet';

import Authentification from './Authentification'

import ClosedEnvelopeIcon from "assets/img/closed-envelope.svg"
import GoogleMapsIcon from "assets/img/google-maps.svg"
import PhoneCallIcon from "assets/img/phone-call.svg"

import './AppFooter.css';

const PHONE_HREF = "tel:1-587-885-2809"
const EMAIL_HREF = "mailto:dbheavymechanical@outlook.com?subject=Greetings%2C%20I%20need%20your%20services%20for%20%5B...%5D&body=%5BThank%20you%20for%20choosing%20us%2C%20please%20write%20down%20your%20needs%20here.%5D%0D%0A%5BDon't%20forget%20to%20write%20your%20name%20and%20your%20phone%20number%20for%20future%20communications%5D"
const MAPS_REF = "https://www.google.com/maps/search/?api=1&query=DoubleBHeavyMechanicalInc&query_place_id=ChIJJ1ElJlaBcVMRByPUN_qQWOc"
const AppFooter = ({ setUser }) => {
  const handleFocus = (event) => {
    window.getSelection().selectAllChildren(event.target);
  }

  const mapsSelector = () => {
    window.open(MAPS_REF);
  }

  return (
    <footer className="app-footer">
      <Footer pad="medium">
        <div className="maps-box noselect" onClick={mapsSelector}>
          <span className="maps-box__text1">Calgary, Alberta</span>
          <img className="maps-box__icon" src={GoogleMapsIcon} />
          <span className="maps-box__text2">Rocky View County</span>
        </div>
      </Footer>
      <Footer className="app-footer__menu" pad="medium">
        <div className="app-footer__menu__phone">
          <a href={PHONE_HREF}>
            <img className="app-footer__menu__phone-icon noselect" src={PhoneCallIcon} alt={"+1 (587) 885-2809"} />
          </a>
          <a className="show-in-mobile" href={PHONE_HREF}>
            <span>+1 (587) 885-2809</span>
          </a>
          <span className="hide-in-mobile" onClick={handleFocus}>+1 (587) 885-2809</span>
        </div>
        <div className="app-footer__menu__email" >
          <a href={EMAIL_HREF}>
            <img className="app-footer__menu__email-icon noselect" src={ClosedEnvelopeIcon} alt={"dbheavymechanical@outlook.com"} />
          </a>
          <a className="show-in-mobile" href={EMAIL_HREF}>
            <span>dbheavymechanical<br />@outlook.com</span>
          </a>
          <span className="hide-in-mobile" onClick={handleFocus} >dbheavymechanical<br />@outlook.com</span>
        </div>
      </Footer>
      <Authentification setUser={setUser} />
    </footer >
  )
}

export default AppFooter