import { Layer, Box, Button } from 'grommet';

import "./Modal.css"

const PHONE_HREF = "tel:1-587-885-2809"

const Modal = ({ showPhone, visible, onClose, content }) => {
  return visible ?
    <Layer
      className='modal'
      position='center'
      onClickOutside={onClose}
      animation="fadeIn"
      onEsc={onClose}
    >
      <Box pad='medium' gap='xsmall' >
        <Button label='X' onClick={onClose} className="modal_close-button" />
        {content}
        <Box direction='row' gap='medium' justify="end" align='start'>
          {showPhone &&
            <a href={PHONE_HREF}>
              <Button label="Call us now" />
            </a>
          }
        </Box>
      </Box>
    </Layer> : null
}

export default Modal