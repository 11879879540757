import { Text } from 'grommet';

const SaleCard = ({ item, openSale }) => {
  return (
    <div onClick={openSale} className="card noselect sale-list_card-expand">
      <div className="thumbnail" style={{ backgroundImage: item.images[0] ? `url(${item.images[0]})` : '' }}>
        <Text className={`state ${item.state.replace(' ', '-').toLowerCase()}`}>{item.state}</Text>
      </div>
      <div className="informations">
        <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
          <Text className="title" >{item.title || "No title"}</Text>
          <Text className="model">{`${item.company} ${item.model} ${item.year}`}</Text>
        </div>
        {!!item.price && item.price != 0 && <Text className="price">{`${item.price}$`}</Text>}
        {!!item.kilometers && item.kilometers != 0 && <Text className="kilometers">{`${item.kilometers} km`}</Text>}
      </div>
    </div>
  );
}

export default SaleCard