import { Card, CardBody, CardFooter, Box, Button } from 'grommet';
import RoadIcon from "assets/img/road.svg"

const PHONE_HREF = "tel:1-403-333-8012"

export const H24ServicesCardModal = () => {
  return (
    <div className="services-modal hide-phone">
      <div className="modal-header">
        <div><img src={RoadIcon} style={{ height: "24px" }} /> 24/7 ROADSIDE ASSISTANCE SERVICE</div>
        <div className="modal-header-description">WE WORK WITH YOU TO ENSURE YOUR SECURITY AND THAT THERE WILL BE NO OVERCHARGING!</div>
      </div>
      <div style={{ fontSize: "20px" }} >CALL TYPES:</div>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div className="modal-service">
          <div style={{ fontSize: "18px", fontWeight: 500 }}>Standard*:</div>
          <div>Cost: 150$/per call  + labour rate (125$/h) </div>
          <div className="modal-service-price" >Additional cost: 0.45$/km for out-of-town call</div>
        </div>
        <div className="modal-divider" />
        <div className="modal-service">
          <div style={{ fontSize: "18px", fontWeight: 500 }}>Maintenance:</div>
          <div>(less than a 50km area)</div>
          <div className="modal-service-price" >Cost: free call + labour rate (98$/h)</div>
        </div>
      </div>
      <div className="modal-note">
        <div>*Cash on Delivery (COD), Accepted Payment Methods: All credit cards, debit, cash.</div>
        <div>**Large inventory of parts available in the road service truck.</div>
      </div>
      <Box direction='row' gap='medium' justify="end" align='start'>
        <a href={PHONE_HREF}>
          <Button label="Call roadside assistance now" />
        </a>
      </Box>
    </div>
  );
}

const H24ServicesCard = ({ h24Image, openSection }) => {

  return (
    <Card onClick={openSection} className="card" height="small" width="small" background="light-1">
      <CardBody className="h24service-image image" pad="medium" style={{ backgroundImage: h24Image ? `url(${h24Image})` : '' }} />
      <CardFooter pad={{ horizontal: "small" }} background="light-2">
        <span className="card-footer__name">24/7 ROADSIDE ASSITANCE</span>
      </CardFooter>
    </Card>
  );
}

export default H24ServicesCard