import { Avatar, Nav, Button } from 'grommet';
import { Link, useLocation } from "react-router-dom";

import Logo from "assets/img/dbheavymechLogo.png"

import './AppBar.css';

const AppBar = ({ adminConfigs }) => {
  const { pathname } = useLocation();

  return (
    <header className={`app-header ${adminConfigs.isAdmin ? 'isAdmin' : ''} ${adminConfigs.isSuperAdmin ? 'isSuperAdmin' : ''}`}>
      <title>Double B Heavy Mechanical Garage Alberta Calgary</title>
      <description>A team of experts to serve you. Trucks, trailer, road services, design. Calgary, Call us now!</description>
      <Nav className="nav-bar noselect" direction="row" background="transparent" pad="medium">
        <div style={{ display: "flex" }}>
          <div className="menu-bar-fulling" />
          <Link to="/services">
            <Button primary label="Services" className={`button-left ${pathname.includes('/services') ? "selected-tab" : ""}`} />
          </Link>
        </div>
        <Link to="/home"><Avatar className="logo noselect" src={Logo} alt={"db heavy mechanical specialists"} /></Link>

        <div style={{ display: "flex" }}>
          <Link to="/store">
            <Button primary label="Store" className={`button-right ${pathname.includes('/store') ? "selected-tab" : ""}`} />
          </Link>
          <div className="menu-bar-fulling" />
        </div>
      </Nav>
    </header >
  )
}

export default AppBar