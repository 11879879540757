import { Card, CardBody, CardFooter } from 'grommet';

import VEngineIcon from "assets/img/v-engine.svg"
import AppointmentIcon from "assets/img/appointment.svg"

export const TruckCardModal = () => {
  return (
    <div className="services-modal">
      <div className="modal-header">
        <div><img src={VEngineIcon} style={{ height: "24px" }} /> TRUCK SERVICES</div>
      </div>
      <div className="modal-service">
        <div>CVIP Inspection: </div>
        <div style={{ paddingLeft: 16 }}>CVIP Power Unit Wheel off</div>
        <div style={{ paddingLeft: 16 }} className="modal-service-price">Cost: 495$</div>
        <div style={{ paddingLeft: 16 }}>CVIP Trailer Wheel off</div>
        <div style={{ paddingLeft: 16 }} className="modal-service-price">Cost: 415$</div>
      </div>
      <div className="modal-service">
        <div>Engine diagnostic</div>
        <div className="modal-service-price">Cost: 120$/diagnostic</div>
      </div>
      <div className="modal-service">
        <div>Engine diagnostic & Repair</div>
        <div className="modal-service-price">Cost: 150$/h</div>
      </div>
      <div className="modal-service">
        <div>General Repair</div>
        <div className="modal-service-price">Cost: 110$/h</div>
      </div>
      <div className="modal-service">
        <div>Monthly Inspection Plan  <img src={AppointmentIcon} style={{ height: "24px" }} /></div>
        <div className="modal-service-price">Cost: 125$/inspection</div>
        <div className="modal-service-price">Cost: 150$/for 2 units</div>
      </div>
      <div className="modal-service">
        <div>RV Repairs</div>
        <div className="modal-service-price">Cost: 110$/h</div>
      </div>
      <div className="modal-service">
        <div>Small Diesel Street Trucks</div>
        <div className="modal-service-price">Cost: 110$/h</div>
      </div>
      <div className="modal-service">
        <div>Transmission Clutch</div>
        <div className="modal-service-price">Cost:110$/h</div>
      </div>
      <div className="modal-service">
        <div>Welding</div>
        <div className="modal-service-price">Cost: 125$/h</div>
      </div>
    </div>
  );
}

const TruckCard = ({ truckImage, openSection }) => {
  return (
    <Card onClick={openSection} className="card" height="small" width="small" background="light-1">
      <CardBody className="truck-image image" pad="medium" style={{ backgroundImage: truckImage ? `url(${truckImage})` : '' }} />
      <CardFooter pad={{ horizontal: "small" }} background="light-2">
        <span className="card-footer__name">TRUCK SERVICES</span>
      </CardFooter>
    </Card>
  );
}

export default TruckCard