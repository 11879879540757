import firebase from 'firebase/app';

import "./Authentification.css"

const signOut = async (setUser) => {
  await firebase.auth().signOut()
  setUser({})
}

const showGoogleAuth = (setUser) => {
  var provider = new firebase.auth.GoogleAuthProvider();
  firebase.auth().signInWithPopup(provider).then(function (result) {
    setUser({})
  }).catch(function (error) {
    // Handle Errors here.
    var errorCode = error.code;
    var errorMessage = error.message;
    // The email of the user's account used.
    var email = error.email;
    // The firebase.auth.AuthCredential type that was used.
    var credential = error.credential;

    console.log(errorCode + "  ---  " + errorMessage + "  ---  " + email + " --- " + credential)
  });
}

const Authentification = ({ setUser }) => {
  return !!firebase.auth().currentUser
    ? <div onClick={() => signOut(setUser)} className="auth auth_sign-out noselect" >Sign out</div>
    : <div onClick={() => showGoogleAuth(setUser)} className="auth noselect" >Sign In</div>
}

export default Authentification