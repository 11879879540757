import { useEffect, useState } from "react"

import './SpeedRow.css';

const SpeedRow = ({
  text, delay, className
}) => {

  const [visible, setVisible] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setVisible(true)
    }, delay)
  }, [])

  if (!visible) return null

  return (
    <div className={`speed-row noselect ${className}`} >
      <span>{text}</span>
    </div>
  )
}

export default SpeedRow