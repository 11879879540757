import { Button, Carousel, Image, Text } from 'grommet';

import "./ViewSale.css"

const ViewSaleItem = ({ item }) => {
  return (
    <div className="sale-modal view-sale">
      {item.images.length ?
        <Carousel className="view-sale-carousel" fill play={item.images.length > 1 ? 4000 : undefined} color="#FFF">
          {item.images.map((image, index) =>
            <Image fit="contain" src={image} key={index} alt={"photo"} />
          )}
        </Carousel>
        : null
      }

      <Text className="view-sale-header">{`${item.title || "No title"}`}</Text>

      <div className="view-sale-infos">
        <Text className="view-sale-subheader">{`${item.company} ${item.model} ${item.year}`}</Text>
        <Text className={`state ${item.state.replace(' ', '-').toLowerCase()}`}>{item.state}</Text>
      </div>

      {(item.price && item.price != 0 || item.kilometers && item.kilometers != 0) &&
        <div className="view-sale-infos">
          {(item.price && item.price != 0) && <Text className="view-sale-infos-price">{`Price: ${item.price}$`}</Text>}
          {(item.kilometers && item.kilometers != 0) && <Text className="view-sale-infos-kms">{`Km: ${item.kilometers}`}</Text>}
        </div>
      }

      <div style={{ height: 8 }} />

      {item.engine && <Text className="view-sale-other-infos">{`Engine: ${item.engine}`}</Text>}
      {item.transmission && <Text className="view-sale-other-infos">{`Transmission: ${item.transmission}`}</Text>}
      {item.drivespec && <Text className="view-sale-other-infos">{`Drivespec: ${item.drivespec}`}</Text>}
      {item.steerspec && <Text className="view-sale-other-infos">{`Steer speec: ${item.steerspec}`}</Text>}
      {item.ratio && <Text className="view-sale-other-infos">{`Ratio: ${item.ratio}`}</Text>}



      {item.description &&
        <Text className="view-sale-description">{item.description.split("\n").map((i, key) =>
          <div key={key}>{i}</div>
        )}</Text>
      }
    </div>
  );
}

export default ViewSaleItem