import ReactStars from "react-rating-stars-component";
import { useEffect } from "react"
import { Box, Carousel, Image } from 'grommet';
import ImageSlider from "react-image-comparison-slider";
import { Link } from "react-router-dom";

import trailerIcon from "assets/img/trailer.svg"
import valetParkingIcon from "assets/img/valet-parking.svg"
import handCoinIcon from "assets/img/handcoin.svg"
import maintenanceIcon from "assets/img/maintenance.svg"
import shopIcon from "assets/img/shop.svg"

import facebookIcon from "assets/img/facebook.svg"

import './Home.css';
import SpeedRow from "./SpeedRow";

const MAPS_REF = "https://www.google.com/maps/search/?api=1&query=DoubleBHeavyMechanicalInc&query_place_id=ChIJJ1ElJlaBcVMRByPUN_qQWOc"

const Home = ({ carouselImages, sliderImages }) => {
  useEffect(() => document.getElementById("appContent").scrollTo(0, 0), [])
  const mapsSelector = () => {
    window.open(MAPS_REF);
  }

  return (
    <div className="home">
      <div className="home__section1">
        <div className="speed-rows">
          <SpeedRow text={<Link to="/services/design">Customize your truck</Link>} delay={0} className="speed-row-1" />
          <SpeedRow text={<Link to="/services">Get professional repairs</Link>} delay={500} className="speed-row-2" />
          <SpeedRow text={<Link to="/store">Visit our store</Link>} delay={1000} className="speed-row-3" />
        </div>

        <div className="home__text title">
          <span style={{ color: "#27aae1", textAlign: 'initial' }}>You have mechanical issues?</span>
          <span className={'fade-in'}>We are your solution.</span>
        </div>

        <div className="home__text home__text-row">
          <div className="home__text-content">
            <div className="home__icon fade-in noselect" style={{ backgroundColor: '#e1274a' }}>
              <img src={trailerIcon} />
            </div>
            <span style={{ fontSize: 24, lineHeight: '105%' }}>Specialized Car Hauler COTTRELL, repair & parts</span>
          </div>
          <div className="home__text-content">
            <div className="home__icon fade-in noselect" style={{ backgroundColor: '#27e1a4' }}>
              <img src={valetParkingIcon} />
            </div>
            <span style={{ fontSize: 24, lineHeight: '105%' }}>We offer courtesy vehicles & we are providing drivers when needed.</span>
          </div>
          <div className="home__text-content">
            <div className="home__icon fade-in noselect" style={{ backgroundColor: '#ffd700' }}>
              <img src={handCoinIcon} />
            </div>
            <span style={{ fontSize: 24, lineHeight: '105%' }}>We offer financing for all your purchases.</span>
          </div>
        </div>
      </div>


      <div onClick={mapsSelector} className="star-review">
        'Always get a professional service!' - Judy V.
        <ReactStars
          classNames="noselect"
          count={5}
          value={5}
          size={24}
          edit={false}
          isHalf={true}
          emptyIcon={<i className="far fa-star"></i>}
          halfIcon={<i className="fa fa-star-half-alt"></i>}
          fullIcon={<i className="fa fa-star"></i>}
          activeColor="#ffd700"
        />
        <span style={{ fontSize: 11.5, color: '#333' }}>Please, rate us on google maps!</span>
      </div>

      <div className="home__section2">
        <div className="home__images">
          <Box className="home__images__carousel fade-in" height="small" width="medium" overflow="hidden">
            {carouselImages.length ?
              <Carousel fill play={5000}>
                {carouselImages.map(image =>
                  <Image fit="cover" src={image} key={image} alt={"Workteam photos"} />
                )}
              </Carousel> : null
            }
          </Box>
          <Box className="home__images__slider fade-in" height="small" width="medium" overflow="hidden">
            {sliderImages.length > 1 &&
              <ImageSlider
                image1={sliderImages[0]}
                image2={sliderImages[1]}
                sliderColor="#27aae1"
                handleColor="#27aae1"
              />
            }
          </Box>
        </div>
        <div className="home__text home__text-row home__links">
          <div className="home__text-content">
            <div className="home__icon fade-in noselect">
              <img src={maintenanceIcon} />
            </div>
            <Link to="/services"><span className="text">Truck and trailer repair, 24/7 roadside service.</span></Link>
          </div>
          <div className="separator" />
          <div className="home__text-content">
            <div className="home__icon fade-in noselect">
              <img src={shopIcon} />
            </div>
            <Link to="/store"><span className="text">Don't forget to check out our store!</span></Link>
          </div>
        </div>
      </div>
      <div className="communication">
        <div className="home__subtext">
          <span style={{ fontSize: 18 }}>Open hours:</span>
          <span style={{ fontSize: 13 }}>Monday to Friday: 7:30 a.m to 5:00 p.m</span>
          <span style={{ fontSize: 13 }}>Saturday: 8:30 to 12:00 a.m, only on request (120$/h)</span>
        </div>
        <div className="home__subtext">
          <span>Phone: +1 (587) 885-2809</span>
          <span>Email: dbheavymechanical@outlook.com</span>
          <span>Address: 9 fulton gate, Rocky View County, AB T1X 0Y8</span>
        </div>
        <div className="social">
          <a className="facebook" href="https://www.facebook.com/doublebheavymechanical" target="_blank">
            <img src={facebookIcon} style={{ height: "24px", paddingRight: '0.25rem' }} />Facebook
          </a>
        </div>
      </div>
    </div >
  )
}

export default Home