import { InfiniteScroll, Text, TextInput, Button, Tabs, Tab, Box } from 'grommet'
import { useEffect, useState } from 'react';
import { useHistory, useLocation } from "react-router-dom";

import { Modal } from "common/components";
import SaleCard from './saleItem/SaleCard'
import ViewSale from './saleItem/ViewSale';
import EditableSale from './saleItem/EditableSale';

import "./SaleList.css"

const SaleCardModal = (item, adminConfigs, editSale, deleteSale) => {
  return adminConfigs.isAdmin || adminConfigs.isSuperAdmin
    ? <EditableSale
      item={item}
      adminConfigs={adminConfigs}
      editSale={editSale}
      deleteSale={deleteSale}
    />
    : <ViewSale item={item} />
}

const SaleList = ({
  saleList,
  adminConfigs: { isAdmin, isSuperAdmin },
  fetchSales,
  createSale,
  editSale,
  deleteSale
}) => {
  const history = useHistory();
  const { pathname } = useLocation();

  const [modalContent, setModalContent] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [showModal, setShowModal] = useState(undefined);

  const [filteredList, setFilteredList] = useState(saleList);

  const openSale = (saleId) => {
    history.push(`/store/${saleId}`);
  }

  const closeSale = () => {
    history.push(`/store`);
  }

  useEffect(() => {
    const saleId = pathname.split('/').pop()
    const selectedSaleId = saleList?.find(({ id }) => id === saleId)

    const adminConfigs = { isAdmin: isAdmin, isSuperAdmin: isSuperAdmin }
    const _editSale = async (uId, data) => {
      await editSale(uId, data);
      setShowModal(false);
    }
    const _deleteSale = async (uId) => {
      await deleteSale(uId);
      setShowModal(false);
    }

    const openModal = async () => {
      if (selectedSaleId && !showModal) {
        await setModalContent(() => SaleCardModal(selectedSaleId, adminConfigs, _editSale, _deleteSale))
        setShowModal(true)
      } else if (!selectedSaleId && showModal) {
        setShowModal(false)
      }
    }

    openModal()
  }, [pathname, saleList])

  useEffect(() => document.getElementById("appContent").scrollTo(0, 0), [])

  useEffect(() => {
    fetchSales(isAdmin || isSuperAdmin)
  }, [isAdmin, isSuperAdmin])


  useEffect(() => {
    if (searchValue.length > 2) {
      setFilteredList(saleList.filter(sale => {
        const searchableField = [
          sale.title,
          sale.company,
          sale.model,
          sale.engine,
          sale.transmission,
          sale.drivespec,
          sale.steerspec,
          sale.ratio,
        ].filter(Boolean)

        const words = searchValue.toLowerCase().split(' ').filter(w => w.length);

        return !!words.filter(w => Object.values(searchableField).filter(fieldContent => fieldContent.toLowerCase().includes(w)).length).length
      }))
    } else {
      setFilteredList(saleList)
    }
  }, [saleList, searchValue])


  if (!Array.isArray(saleList)) {
    return null;
  } else if (!saleList.length) {
    return (
      <div className="sale-section" >
        <Text className="sale-section-title">Store</Text>

        {isAdmin &&
          <div onClick={createSale} className="add-sale noselect">
            <span>+</span>
          </div>
        }
        <div className="sale-list">Empty for now, please come back later!</div>
      </div>
    );
  }


  const saleTabList = filteredList ? filteredList.filter(s => s.state !== "Service" && s.state !== "Product") : []
  const productTabList = filteredList ? filteredList.filter(s => s.state === "Product" || s.state === "Creating") : []
  const serviceTabList = filteredList ? filteredList.filter(s => s.state === "Service" || s.state === "Creating") : []

  const getTabTitle = (number, word) => number ? `${number} ${word}s` : `${number} ${word}`

  return (
    <div className="sale-section" >
      <div className="sale-section-title" >
        <Text style={{ fontSize: "2rem", marginBottom: 8 }}>Store</Text>

        <div style={{ display: 'flex', alignItems: 'center' }}>
          <TextInput
            maxLength={50}
            placeholder="Search"
            value={searchValue}
            onChange={event => setSearchValue(event.target.value)}
          />
          <Button primary label="clear" className="clear-button" onClick={() => setSearchValue("")} />
        </div>
      </div>

      {isAdmin &&
        <div onClick={createSale} className="add-sale noselect">
          <span>+</span>
        </div>
      }

      <Tabs>
        <Tab title={getTabTitle(saleTabList.length, 'sale')}>
          <div className="sale-list">
            {saleTabList.length
              ? <InfiniteScroll scrollableAncestor={'window'} items={saleTabList}>
                {(item) => (
                  <SaleCard
                    key={item.id}
                    item={item}
                    openSale={() => openSale(item.id)}
                  />
                )}
              </InfiniteScroll>
              : !!searchValue ? "No search result!" : "Empty"
            }
          </div>
        </Tab>
        <Tab title={getTabTitle(productTabList.length, 'product')}>
          <div className="sale-list">
            {productTabList.length
              ? <InfiniteScroll scrollableAncestor={'window'} items={productTabList}>
                {(item) => (
                  <SaleCard
                    key={item.id}
                    item={item}
                    openSale={() => openSale(item.id)}
                  />
                )}
              </InfiniteScroll>
              : !!searchValue ? "No search result!" : "Empty"
            }
          </div>
        </Tab>
        <Tab title={getTabTitle(serviceTabList.length, 'service')}>
          <div className="sale-list">
            {serviceTabList.length
              ? <InfiniteScroll scrollableAncestor={'window'} items={serviceTabList}>
                {(item) => (
                  <SaleCard
                    key={item.id}
                    item={item}
                    openSale={() => openSale(item.id)}
                  />
                )}
              </InfiniteScroll>
              : !!searchValue ? "No search result!" : "Empty"
            }
          </div>
        </Tab>
      </Tabs>

      <Modal showPhone={!(isAdmin || isSuperAdmin)} content={modalContent} visible={showModal} onClose={closeSale} />
    </div>
  )
}

export default SaleList