import { Card, CardBody, CardFooter, Box, Carousel, Image } from 'grommet';
import { useEffect } from 'react';

import ColorSchemeIcon from "assets/img/color-scheme.svg"

export const CustomCardModal = (carouselImages = []) => {
  return (
    <div className="services-modal">
      <Box className="fade-in services-carousel" height="small" width="medium" overflow="hidden">
        {carouselImages.length ?
          <Carousel fill play={5000}>
            {carouselImages.map(image =>
              <Image fit="cover" src={image} key={image} alt={"custom photos"} />
            )}
          </Carousel> : null
        }
      </Box>
      <div className="modal-header">
        <div><img src={ColorSchemeIcon} style={{ height: "24px" }} />  DBHM Design Division</div>
        <div className="modal-header-description">Would you like to improve your truck?</div>
        <div className="modal-header-description">With us, your ideas will become reality</div>
      </div>
      <div className="modal-service">
        <div style={{ fontSize: 24 }}>Services:</div>
      </div>
      <div className="modal-service">
        <div style={{ paddingLeft: 10 }}>Chrome Order</div>
      </div>
      <div className="modal-service">
        <div style={{ paddingLeft: 10 }}>Cab Design & built</div>
      </div>
      <div className="modal-service">
        <div style={{ paddingLeft: 10 }}>Polishing & component paint</div>
      </div>
      <div className="modal-service">
        <div style={{ paddingLeft: 10 }}>Decal design & installation</div>
      </div>
      <div className="modal-note">
        <div>And more! Call us for more informations</div>
      </div>
    </div>
  );
}

const CustomCard = ({ designImage, openSection }) => {
  return (
    <Card onClick={openSection} className="card" height="small" width="small" background="light-1">
      <CardBody className="custom-image image" pad="medium" style={{ backgroundImage: designImage ? `url(${designImage})` : '' }} />
      <CardFooter className="custom-footer" pad={{ horizontal: "small" }} background="light-2">
        <span className="card-footer__name">DBHM Design Division</span>
      </CardFooter>
    </Card>

  );
}

export default CustomCard