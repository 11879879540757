import { Card, CardBody, CardFooter } from 'grommet';

import WrenchIcon from "assets/img/wrench.svg"

export const TrailerCardModal = () => {
  return (
    <div className="services-modal">
      <div className="modal-header">
        <div><img src={WrenchIcon} style={{ height: "24px" }} /> TRAILER SERVICES</div>
      </div>
      <div className="modal-service">
        <div>General Repair</div>
        <div className="modal-service-price">Cost: 110$/h</div>
      </div>
      <div className="modal-service">
        <div>General Repair (car hauling)</div>
        <div className="modal-service-price">Cost: 110$/h</div>
      </div>
      <div className="modal-service">
        <div>Light / Small Trailer</div>
        <div className="modal-service-price">Cost: 110$/h</div>
      </div>
      <div className="modal-service">
        <div>Welding Standard Trailers</div>
        <div className="modal-service-price">Cost: 125$/h</div>
      </div>
      <div className="modal-service">
        <div>Welding Car Hauler</div>
        <div className="modal-service-price">Cost: 125$/h</div>
      </div>
    </div>
  );
}

const TrailerCard = ({ trailerImage, openSection }) => {
  return (
    <Card onClick={openSection} className="card" height="small" width="small" background="light-1">
      <CardBody className="trailer-image image" pad="medium" style={{ backgroundImage: trailerImage ? `url(${trailerImage})` : '' }} />
      <CardFooter pad={{ horizontal: "small" }} background="light-2">
        <span className="card-footer__name">TRAILER SERVICES</span>
      </CardFooter>
    </Card>
  );
}

export default TrailerCard